body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.disclaimer-content {
  padding: 10%;

  h2 {
    color: #0C2340;
    text-transform: uppercase;
    padding-bottom: 1.25rem;
  }

  p {
    color: #777;
  }
}

.my-product-container {
  a {
    &:focus {
      outline: none;
    }

    &:hover {
      text-decoration: none;
    }
  }

  button[type=submit] {
    border: none;
    background: white;
    padding: 0;
    text-align: left;
    width: 100%;
  }
}

.main-menu {
  .list-nav {
    &.portal-home {
      .menuicon {
        background-color: #757575;
      }

      .menulist {
        &:hover {
          background-color: #757575;
        }
      }
    }
  }
}

.widget-box {
  &.widget-box-bg {
    filter: invert(90%);

    .widget-content {
      * {
        color: #fff;
      }
    }
  }
}

.widget-box {
  &.no-border {
    border-top: none;
  }
}

.main-navigation .navbar-left .page-logo img {
  max-height: 2.813rem;
  padding-top: 0.125rem;
}

.client-branding {
  border-bottom: 0.063rem solid #0C2340;
  background-color: white !important;

  .main-menu {
    .menu-btn {
      background-color: white;
    }
  }

  .product-title {
    color: #0C2340;
  }

  .help-icon {
    span {
      color: #0C2340;
    }
  }

  .user-info {
    span {
      color: #0C2340;
    }
  }

  .user-info:after {
    border-top: 0.125rem solid #0C2340 !important;
    border-right: 0.125rem solid #0C2340 !important;
  }

  .verticle-Line {
    background-color: #0C2340 !important;
  }
}

.widget-box .widget-content .my-product-container .my-product-item .product-title .sub-title * {
  font-size: 1rem;
  line-height: 1.1;
}

.widget-box .widget-content p {
  font-size: 1rem;
}

.tall-logo {
  height: 4.375rem;

  .menu-btn {
    width: 4.063rem;
    height: 4.375rem;

    img {
      width: 2.188rem;
      height: 2.188rem;
    }
  }
}

.report-container {
  .report {
    height: calc(0.7 * 61vw);

    /* 16:9 aspect ratio */
    iframe {
      border: 0px;
    }
  }
}

h3.back-button {
  font-family: "Volte-Semibold";
  font-size: 1rem;
  position: relative;
  padding-left: 1.25rem;
  margin-right: 9.063rem;
  display: inline-block;
  cursor: pointer;
}

h3.back-button:before {
  content: "";
  position: absolute;
  top: 0.375rem;
  border-top: 0.125rem solid #333;
  border-right: 0.125rem solid #333;
  opacity: .7;
  left: 0;
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
  display: inline-block;
  width: 0.625rem;
  height: 0.625rem;
}

.report-toolbar {
  background: #f8f8f8;
  border-bottom: 0.063rem solid #ccc;
  padding: 1.125rem 0.625rem 0.625rem;
  top: 3.125rem;
  width: 100%;
  z-index: 9;
  height: 6.063rem;
  display: flex;
  align-items: center;
}

.report-selection {
  max-width: 12.5rem;
}

.multi-select-dropdown.msd-v3.report-selection .list-panel {
  .search-icon {
    display: none;
  }

  .search-icon+input {
    display: none;
  }
}

button.link:hover,
button.link:focus {
  background: #f8f8f8;
}

.zoom-icon {
  float: left;
  font-size: 0.75rem;

  img {
    height: 1.25rem;
    width: 1.25rem;
    padding-right: 0.313rem;
  }
}

.container-fluid .widget-box.marketing-widget {
  width: 100%;
}

.container-fluid .widget-box {
  width: 100%;
}

.beamer_defaultBeamerSelector {
  display: none;
}

.widget-box .widget-heading {
  margin-bottom: 0.625rem;
}

.widget-box .widget-content {
  height: calc(100% - 0.625rem);
}

.widget-box.marketing-widget .widget-content a {
  display: inline-block;
}

@media only screen and (max-width: 64rem) {
  .widget-box .widget-content .my-product-container .my-product-item .product-title {
    font-size: 1rem;
    padding-left: 2rem !important;
  }

  .widget-box .widget-heading {
    max-width: 70%;
  }

  .widget-box.widget-box-large .widget-heading {
    max-width: 100%;
  }
}

@media (min-width: 48rem) and (max-width: 81rem) {
  .widget-box .widget-heading div.my-products-tab .btn-group .btn {
    font-size: 0.813rem;
  }

  .widget-box.marketing-widget .widget-content a {
    padding: 0.625rem 1.25rem;
  }

  .widget-box {
    &.widget-box-large .widget-content .my-product-container .my-product-item {
      padding: 0 0 0 0.625rem;
      min-height: auto;

      .product-icon {
        img {
          max-height: 2.5rem;
          max-width: 100%;
        }
      }

      .product-title {
        padding-left: 1.4rem !important;
      }
    }
  }

  .widget-box.marketing-widget .widget-content p {
    font-size: 0.8rem;
  }

  .widget-box .widget-content p {
    font-size: 0.8rem;
  }

  .widget-box .widget-heading {
    max-width: 70%;
    font-size: 1rem;
  }

  .widget-box.marketing-widget .widget-heading {
    font-size: 1rem;
  }

  .widget-box.widget-box-large .widget-heading {
    max-width: 100%;
  }

  .axco-html-container p {
    margin-bottom: 6px;
  }

}

@media (min-width: 48rem) and (max-width: 64rem) {
  .my-product-container {
    .product-icon {
      img {
        max-width: 4.063rem;
      }
    }
  }

  .widget-box .widget-content .my-product-container .my-product-item .product-title {
    font-size: 1rem;
    padding-left: 2rem !important;
  }

  .widget-box.marketing-widget .widget-content a {
    font-size: 0.875rem;
  }

  .widget-box .widget-heading {
    max-width: 70%;
  }

  .widget-box.widget-box-large .widget-heading {
    max-width: 100%;
  }

  .axco-html-container span {
    font-size: 0.875rem !important;
  }

  .axco-html-container p {
    margin-bottom: 0px;
  }

  .widget-box .widget-content p {
    font-size: 0.813rem;
  }
}

@media (min-height: 0px) and (max-device-height: 43.75rem) {
  .widget-box .widget-content p {
    font-size: 0.813rem;
  }
}

@media only screen and (min-width : 64.063rem) {
  .widget-box .widget-content .my-product-container.cnt-3 .my-product-item .product-icon img {
    height: 5rem;
  }

  .widget-box .widget-content .my-product-container.cnt-6 .my-product-item .product-icon img {
    height: 3.438rem;
  }

  .widget-box .widget-content .my-product-container.cnt-2 .my-product-item .product-icon img {
    height: 6.25rem;
  }
}

#tableau-frame {
  height: calc(100% - 12.625rem);
  width: 100%;
  border: none;
}

body.dark-mode {
  background-color: #000000;

  .gs-error-screen {

    .b-gsErrorPage-disclaimer,
    .b-gsErrorPage-hint {
      color: #fff;
    }
  }

  .userAccount .user-account-banner .heading {
    color: #fff;
  }

  .navbar-fixed-top {
    background: #222222;

    .main-menu {

      .list-nav.portal-home {
        .active {
          background-color: #757575;

          &:hover {
            background-color: #757575;

          }
        }

        &:hover {
          background-color: #757575;

        }
      }

      .dropdown-menu.waffle-menu-container {
        background-color: #000000;
      }

      .list-nav {
        background: #333;

        .menulist {
          color: #fff;

          &:hover {
            background-color: #FF4339;
          }
        }
      }

      .menu-btn {
        background-color: #181818;

        &:hover {
          background-color: #fff;
        }
      }
    }
  }

  .page-footer {
    background-color: #000000;
    color: #fff;
  }

  #myProductItem-tooltip {
    .tooltip-inner {
      border: 0.063rem solid #fff;
      background-color: #ffffff;
      color: #000;
    }
  }

  .bs-tooltip-right .arrow::before,
  .bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #fff;
  }


  .tooltip {
    color: #333333;
  }

  .landing-container {
    background: #000000;

    .widget-box {
      // &.marketing-widget {

      // }
      position: relative;

      &.widget-box-large {
        background: #222222;
      }

      .widget-heading {
        // color: #fff;

        div.my-products-tab .btn-group {
          .btn-outline-secondary:not(:disabled):not(.disabled).active:focus {
            box-shadow: none;
          }

          .btn {
            border-color: #fff;
            color: #0C2340;
            background-color: #fff;

            &:hover {
              background-color: #FF4339;
              color: #fff;
              border-color: #FF4339;

            }
          }

          .btn.active {
            background-color: #FF4339;
            color: #fff;
            border-color: #FF4339;

          }
        }
      }

      .widget-content {
        p {
          color: #fff
        }

        .my-product-container {
          .my-product-item {
            background: #222;

            &:hover {
              background-color: #FF4339;
              color: #fff;

              .product-title .sub-title {
                color: #fff;
              }
            }

            .product-title {
              color: white;

              .sub-title {
                color: #FF4339;
              }
            }

            button[type="submit"] {
              background: #222;
            }
          }
        }
      }
    }

  }
}