@import '@axco/react.library/dist/theme/custom_variable';
.landing-container{
    background-color: #F9F9F9;
    min-height: calc(100vh - 5.625rem);
    padding-top: 1.25rem;
}
.widget-box{
    background-color: $white;
    padding: 1.563rem;
    margin-bottom: 1.875rem;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
    border-top: 0.125rem solid #FF4339;
    height: calc(50vh - 5.469rem);
    background-size: auto;
    background-position: top right;
    background-repeat: no-repeat;
    &.hide-title {
        .widget-heading {
            display: none;
        }
        .widget-content {
            height: calc(100% - 1.25rem);
        }
    }
    &.my-product-title {
        .widget-content {
            height: calc(100% - 3.75rem);
        }
    }
    &.widget-box-large{
        height: calc(100vh - 9.063rem);
        overflow: auto;
        padding-right: 0;
        padding-bottom: 0.313rem;
    } 
    &.widget-box-bg{
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        .widget-heading{
            color: #fff;
        }
    }  
    .widget-heading{
        margin-top: 0;
        margin-bottom: 1.875rem;
        color: #0C2340;
        font-size: $font-lg;
        font-family: 'Volte-Semibold';
        div.my-products-tab {
            width: 100%;
            min-height: auto;
            .btn-group {
                display: flex;
                padding-right: 0.938rem;
                padding-bottom: 0.625rem;
                .btn {
                    border-color: #0C2340;
                    text-transform: uppercase;
                    color: #0C2340;
                    width: 50%;
                    &.active, &:hover {
                        background-color: #0C2340;
                        color: #FFFFFF;
                    }
                }
            }
        }
    }
    .widget-content{
        overflow: auto;
        height: calc(100% - 1.875rem);

        .widget-icon{
            font-size: 3.875rem;
            font-family: 'Volte-Semibold';
            color: $orange;
            line-height: 0.9;
            display: inline-block;
        }
        & > div:first-of-type{
            min-height: 5.625rem;
        }
        p{
            font-size: $font-ru;
            color: #444444;
            line-height: 1.1;
        }
        .widget-more{
            margin-top: 1.25rem;
            a{
                color: #0C2340;
                font-weight: bold;
                font-size: $font-sm;
                img{
                    transform: rotate(90deg);
                    -webkit-transform: rotate(90deg);
                    width: 1rem;
                }
            }
        }
        .contact-details{
            li{
                p{
                    color: $white;
                    font-size: $font-lg;
                    margin-bottom:0.313rem;
                }
                h4{
                    color: #60BBE3;
                    font-size: $font-lg;
                    margin-bottom:0.313rem;
                }
                border-bottom: 0.063rem solid rgba(221, 221, 221, 0.3);
                padding: 1.875rem 0;
                &:last-of-type{
                    border-bottom: 0;
                    padding-bottom:0;
                }
                &:first-of-type{
                    padding-top:0;
                }
            }
        }
        div.my-products-tab {
            width: 100%;
            min-height: auto;
            .btn-group {
                display: flex;
                padding-right: 0.938rem;
                padding-bottom: 0.625rem;
                .btn {
                    border-color: #0C2340;
                    text-transform: uppercase;
                    color: #0C2340;
                    width: 50%;
                    &.active, &:hover {
                        background-color: #0C2340;
                        color: #FFFFFF;
                    }
                }
            }
        }
        .my-product-container {
            align-items: stretch;
            display: flex;
            flex-direction: column;
            overflow: auto;
            padding-right: 0.625rem;
            a {
                display: block;
            }
            /* width */
            &::-webkit-scrollbar {
                width: 0.313rem;
            }
            /* Track */
            &::-webkit-scrollbar-track {
                background: #ffffff; 
            }
            
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #dddddd; 
            }
            
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #888888; 
            }
            .my-product-item {
                border-bottom: 0.063rem solid #DDDDDD;
                flex: 1;
                display: flex;
                flex-direction: row;
                padding: 0.625rem 0 0.625rem 0.625rem;
                min-height: 5rem;
                align-items: center;
                align-content: center;
                flex-wrap: wrap;
                margin-right: 0;
                overflow: hidden;
                .product-icon{
                    height: 100%;
                    color: #fff;
                    text-align: left;
                    padding: 0.313rem;
                    img {
                        max-height: 3.75rem;
                        padding-left: 0.625rem;
                    }
                }
                .product-title {
                    padding-left: 0.625rem;
                    color: #333333;
                    font-family: 'Volte-Medium';
                    font-size: 1.125rem;
                    .sub-title {
                        color: #333300;
                        font-family: Arial;
                        font-size: 0.75rem;
                        * {
                            font-size: 0.75rem;
                        }
                    }
                    .more-btn {
                        font-size: 1.25rem;
                    }
                }
                .product-desc {
                    flex-basis: 100%;
                    color: #666666;
                    font-family: Arial;
                    font-size: 0.75rem;
                    padding-top: 0.938rem;
                }
                &:hover {
                    background-color: #0C2340;
                    .product-title, .sub-title, .product-desc, .product-desc p {
                        color: #FFFFFF;
                    }
                }
            }
            &.cnt-2{
                .my-product-item {
                    align-content: flex-start;
                    .product-icon{
                        img {
                            max-height: 6.25rem;
                        }
                    }
                }
            }
            &.cnt-3{
                .my-product-item {
                    .product-icon{
                        margin-left: -0.938rem;
                        img {
                            max-height: 5rem;
                            padding-left: 1.563rem;
                            
                        }
                    }
                }
                .product-title {
                    font-size: 1.25rem;
                }
            }
            &.cnt-6{
                .my-product-item {
                    .product-icon{
                        margin-left: -1.25rem;
                        img {
                            max-height: 3.438rem;
                            padding-left: 1.875rem;
                        }
                    }
                }
            }
        }
        /* width */
        &::-webkit-scrollbar {
            width: 0.313rem;
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #aaa;
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }
    &.about-axco {
        .widget-content {
            p:first-of-type {
                padding-left: 3.75rem;
            }
        }
        
    }
    &.static-widget {
        background-size: 100% 100%;
    }
    &.marketing-widget {
        background-size: 100% 100%;
        cursor: pointer;
        .widget-heading {
            color: $white;
        }
        .widget-content {
            p {
                color: $white;
            }
            a {
                background-color: $orange;
                padding: 0.938rem 1.25rem;
                color: $white;
                margin-top: 0.625rem;
                text-decoration: none;
                font-family: 'Volte-Semibold';
                font-size: 1.125rem;
                border-radius: 0.188rem;
            }
        }
    }
}
#myProductItem-tooltip{
    .tooltip-inner {
        border: 0.063rem solid #000;
        text-align: left;
        background-color: #0C2340;
        max-width: 25rem;
        color: #ffffff;
    }
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #0C2340;
}


.tooltip {
    color: #333333;
    .tooltip-header {
      display: block;
      font-family: 'Volte-Semibold';
      font-size: 1rem;
      font-weight: bold;
      * {
        font-family: Arial;
        font-size: 1.125rem;
        font-weight: bold;
      }
    }
    .tooltip-content {
      font-family: Arial;
      font-size: 0.875rem;
    }
  }
  .main-menu {
    .list-nav {
        .menulist {
            color: #999999;
        }
    }
  }
  @media (max-width: 47.969rem) {
    .content {
        padding-bottom: 3.125rem;
    }
    .page-footer {
        padding-left: 0.625rem;
        padding-right: 0.625rem;
    }
    .widget-box {
        &.widget-box-large {
            height: auto;
            padding-left: 0.938rem;
            .widget-content{
                .my-product-container {
                    height: 100%;
                    .my-product-item{
                        .product-icon {
                            margin-left: -1.563rem;
                            img {
                                max-height: 2.813rem;
                            }
                        }
                        .product-title {
                            font-size: 1rem;
                            padding-right: 0.313rem;
                        }
                    }
                }
            }
        }
    }
  }
  .tooltip.show {
      opacity: 1;
  }